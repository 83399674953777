/**
 * https://github.com/firebase/firebase-js-sdk/issues/519#issuecomment-366509360
 * https://stackoverflow.com/questions/39914899/using-firebase-auth-to-access-the-google-calendar-api
 *
 * Calendar event: https://developers.google.com/calendar/api/v3/reference/events
 *
 * calendar api example: https://developers.google.com/calendar/api/quickstart/js#set_up_the_sample
 * Kassenbuch Vorlage: https://excelnova.org/wp-content/uploads/2014/04/image27.png
 */
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, TextField, Typography } from "@mui/material";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { CisGoogleAuth } from "@/ModuleCalendarToIncomeStatement/CisGoogleAuth";
import { CisNav } from "@/ModuleCalendarToIncomeStatement/CisNav";
import { CisTables } from "@/ModuleCalendarToIncomeStatement/CisTables";
import { getCalendarsResponse, getEventsResponse } from "@/ModuleCalendarToIncomeStatement/taxDemoData";
import {
    CisCalendarsResponse,
    CisData,
    CisEventItem,
    CisEventsResponse,
    CisMonth,
    getMonthInfo
} from "@/ModuleCalendarToIncomeStatement/CisUtils";
import format from "date-fns/format";
import { cisRenderExcel } from "@/ModuleCalendarToIncomeStatement/cisRenderExcel";
import { useCisPersistStore } from "@/stores/useCisPersistStore";
import { useSigninCheck } from "reactfire";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";

export type CisAccessTkn = string | "DEV_ACCESS_TOKEN";

export function CISMainPage() {
    const devMode = useQueryParam("dev-mode");  // if true, use demo data and don't make google api calls
    const { data: userData } = useSigninCheck();

    const taxNumber = useCisPersistStore(state => state.taxNumber);
    const setTaxNumber = useCisPersistStore(state => state.setTaxNumber);

    const tableTitle = useCisPersistStore(state => state.tableTitle);
    const setTableTitle = useCisPersistStore(state => state.setTableTitle);

    const [fileName, setFileName] = useState("EÜR.xlsx");

    const isTaxNumberValid = useMemo<boolean>(() => {
        return taxNumber.trim().length > 0;
    }, [taxNumber]);

    const isTableTitleValid = useMemo<boolean>(() => {
        return tableTitle.trim().length > 0;
    }, [tableTitle]);

    const isFileNameValid = useMemo<boolean>(() => {
        return fileName.trim().endsWith(".xlsx");
    }, [fileName]);

    const [accessTkn, setAccessTkn] = useState<CisAccessTkn>("");
    const [allCalendars, setAllCalendars] = useState<CisCalendarsResponse | null>(null);
    const [isAllCalendarsLoading, setIsAllCalendarsLoading] = useState(false);
    const [calendarData, setCalendarData] = useState<CisEventsResponse | null>(null);
    const [isCalendarDataLoading, setIsCalendarDataLoading] = useState(false);

    // TODO: Fill this with the data from calendarData and replace the calendarDataMonths from CisTables.tsx with this
    const [cisData, setCisData] = useState<CisData>({ months: {} });

    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [displayError, setDisplayError] = useState("");

    // TODO: Remove this at some point
    useEffect(() => console.log("> userData", userData), [userData]);
    useEffect(() => console.log("allCalendars", allCalendars), [allCalendars]);
    useEffect(() => console.log("calendarData", calendarData), [calendarData]);
    useEffect(() => console.log("cisData", cisData), [cisData]);

    /** fill cisData */
    function splitEventsByMonth(): Record<string, CisMonth> {
        const months: Record<string, CisMonth> = {};
        console.log('splitEventsByMonth', 'calendarData', calendarData);
        try {
            calendarData?.items
                ?.filter(item => item.status === "confirmed")
                // Filter out events out of selected date range
                ?.filter(item => {
                    const _startDate = new Date(item.start?.dateTime || item.start?.date);
                    const _endDate = new Date(item.end?.dateTime || item.end?.date);
                    return _startDate >= startOfDay(startDate) && _endDate <= endOfDay(endDate);
                })
                ?.sort((a: any, b: any) => {
                    const dateA = new Date(a.start?.dateTime || a.start?.date || 0);
                    const dateB = new Date(b.start?.dateTime || b.start?.date || 0);
                    return dateA.getTime() - dateB.getTime();
                })
                ?.forEach((item: CisEventItem) => {
                    const _startDate = new Date(item.start?.dateTime || item.start?.date);
                    const monthKey = format(_startDate, "yyyy-MM");
                    if (!months[monthKey]) {
                        months[monthKey] = { events: [], selectedEventIds: [] };
                    }
                    months[monthKey].events.push({
                        id: item.id || "",
                        data: item,
                        editedIncome: "test vvv",
                        editedExpense: ""
                    });
                });
        } catch (err) {
            const msg = "Fehler beim Verarbeiten der Termin-Daten";
            setDisplayError(msg);
            console.error(err);
        }
        return months;
    }

    /** Fill cisData */
    useEffect(() => {
        setCisData({ months: splitEventsByMonth() })
    }, [calendarData]);


    /**
     * Get all calendars
     */
    function getAllCalendars() {
        const url = "https://www.googleapis.com/calendar/v3/users/me/calendarList";
        setIsAllCalendarsLoading(true);
        fetch(url, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => setAllCalendars(data))
            .catch(err => {
                const msg = "Die Kalender konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            })
            .finally(() => setIsAllCalendarsLoading(false));
    }

    useEffect(() => {
        if (!accessTkn) return;
        if (devMode.value) {
            console.info("DEV MODE: Setting allCalendars to demo data");
            setAllCalendars(getCalendarsResponse);
        } else {
            getAllCalendars();
        }
    }, [accessTkn]);


    /**
     * Get all events from selected calendar
     */
    function getCalendarEvents(calId: string) {
        // const calId = "c7fb666022305dfa421e5026f983950223345c68ae8f422753d901c91e20b3ec@group.calendar.google.com";
        const listUrl = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events`;
        setIsCalendarDataLoading(true);

        /** Reset cisData */
        setCisData({ months: {} });

        const search = new URLSearchParams({
            maxResults: "10000",
            timeMin: startOfDay(startDate).toISOString(),
            timeMax: endOfDay(endDate).toISOString(),
        });
        fetch(`${listUrl}?${search.toString()}`, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => setCalendarData(data))
            .catch(err => {
                const msg = "Die Termine konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            })
            .finally(() => setIsCalendarDataLoading(false));
    }

    useEffect(() => {
        if (!selectedCalendar) return;
        if (devMode.value) {
            console.info("DEV MODE: Setting calendarData to demo data");
            setCalendarData(getEventsResponse);
        } else {
            getCalendarEvents(selectedCalendar);
        }
    }, [selectedCalendar, startDate, endDate]);


    const disableExportButton = useMemo<boolean>(() => {
        if (!isTaxNumberValid || !isTableTitleValid || !isFileNameValid) return true;
        return !Object.values(cisData.months).some(month => month.selectedEventIds.length > 0);
    }, [cisData, isTaxNumberValid, isTableTitleValid, isFileNameValid]);

    return <div className="min-h-full flex flex-col">
        <Typography variant="h1" sx={{ pb: 4 }}>
            Umsätze zu Excel Exportieren
        </Typography>

        <div className="flex flex-col gap-4 items-start flex-1">
            {displayError &&
                <Alert color="error" icon={false} onClose={() => setDisplayError("")}>
                    {displayError}
                </Alert>
            }

            {/** Enable/disable local dev mode */}
            {window.location.hostname === "localhost" &&
                <Button
                    size="small"
                    variant="outlined"
                    color={devMode.value ? "warning" : "inherit"}
                    onClick={() => devMode.value ? devMode.remove() : devMode.set("1")}
                >
                    dev mode (mock data): {devMode.value ? "enabled" : "disabled"}
                </Button>
            }

            {!accessTkn ?
                <CisGoogleAuth
                    setAccessTkn={setAccessTkn}
                    isDevMode={!!devMode.value}
                />
                :
                <>
                    <CisNav
                        selectedCalendar={selectedCalendar}
                        setSelectedCalendar={setSelectedCalendar}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        allCalendars={allCalendars}
                        isAllCalendarsLoading={isAllCalendarsLoading}
                    />

                    {/** Tables */}
                    <div className="w-full flex flex-col gap-10 relative min-h-56">
                        {isCalendarDataLoading &&
                            <LoadingSpinnerWithBackdrop/>
                        }
                        {!!selectedCalendar &&
                            <CisTables cisData={cisData} setCisData={setCisData}/>
                        }
                        {!isCalendarDataLoading && !!selectedCalendar && Object.values(cisData.months).length === 0 &&
                            <Typography variant="h4" sx={{ mt: 5 }}>
                                Keine Termine für den ausgewählten Zeitraum gefunden
                            </Typography>
                        }
                    </div>

                    {/** Summary */}
                    {selectedCalendar && Object.values(cisData.months).length > 0 &&
                        <div className="w-full flex flex-col items-start gap-4">
                            <Typography className="pt-5" variant="h4">Zusammenfassung</Typography>

                            <div className="pb-2">
                                {Object.entries(cisData.months).map(([monthKey, month]) => {
                                    const monthInfo = getMonthInfo(monthKey);

                                    return <div key={monthKey}>
                                        <b>{monthInfo.monthName} {monthInfo.yearNumber}:</b>{" "}
                                        {month.selectedEventIds.length === 0 ?
                                            "0 Einträge ausgewählt" :
                                            `${month.selectedEventIds.length} von ${month.events.length} Einträge ausgewählt`
                                        }
                                    </div>
                                })}
                            </div>

                            <TextField
                                value={taxNumber}
                                onChange={e => setTaxNumber(e.target.value)}
                                label="Steuernummer"
                                sx={{ minWidth: '300px' }}
                                error={!isTaxNumberValid}
                                helperText={!isTaxNumberValid && "Steuernummer darf nicht leer sein"}
                            />
                            <TextField
                                value={tableTitle}
                                onChange={e => setTableTitle(e.target.value)}
                                label="Tabellenüberschrift"
                                sx={{ minWidth: '300px' }}
                                error={!isTableTitleValid}
                                helperText={!isTableTitleValid && "Tabellenüberschrift darf nicht leer sein"}
                            />
                            <TextField
                                value={fileName}
                                onChange={e => setFileName(e.target.value)}
                                label="Dateiname"
                                sx={{ minWidth: '300px' }}
                                error={!isFileNameValid}
                                helperText={!isFileNameValid && "Dateiname muss mit '.xlsx' enden"}
                            />

                            {/* TODO: Filer that only the selected items are given to cisRenderExcel */}
                            <Button
                                onClick={() => cisRenderExcel(cisData, { taxNumber, fileName, tableTitle })}
                                disabled={disableExportButton}
                            >
                                Als Excel exportieren
                            </Button>
                        </div>
                    }
                </>
            }
        </div>
    </div>;
}
